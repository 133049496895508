import { type Dispatch, type SetStateAction, useEffect } from 'react'

//Source: https://github.com/albingroen/react-cmdk/blob/main/src/lib/utils.tsx

export function useSearchKeyboardShortcut(setIsOpen: Dispatch<SetStateAction<boolean>>) {
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        (navigator?.userAgent?.toLowerCase().includes('mac') ? e.metaKey : e.ctrlKey) &&
        e.key === 'k'
      ) {
        e.preventDefault()
        e.stopPropagation()

        setIsOpen((currentValue) => {
          return !currentValue
        })
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
}
