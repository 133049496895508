import { useEffect } from 'react'

import { hotjar } from 'react-hotjar'

const isProductionEnv = process.env.NODE_ENV === 'production'

export function useHotjar() {
  useEffect(() => {
    isProductionEnv &&
      hotjar.initialize(
        Number(process.env.NEXT_PUBLIC_HOTJAR_HJID),
        Number(process.env.NEXT_PUBLIC_HOTJAR_HJSV),
      )
  }, [])
}
