import VerifiedBadge from '@components/VerifiedBadge'
import { cx } from '@helpers/cx'

type Props = {
  email: string
  firstName: string | null
  lastName: string | null
  isVerified?: boolean
}

export default function UserEmailColumn({ email, firstName, isVerified, lastName }: Props) {
  const data = []

  if (firstName || lastName) {
    data.push(
      <span
        key={`name-${email}`}
        className={cx('block', {
          'text-xs text-gray-500': email,
        })}
      >
        {firstName && `${firstName} `}
        {lastName && lastName}
      </span>,
    )
  }

  if (email) {
    data.push(<span key={`email-${email}`}>{email}</span>)
  }

  if (!data.length) {
    return (
      <div className='flex items-end gap-x-2 text-sm italic text-gray-600'>
        Not provided {isVerified && <VerifiedBadge />}
      </div>
    )
  }

  return (
    <div className='flex items-end gap-x-2'>
      <div>{data}</div>
      {isVerified && <VerifiedBadge />}
    </div>
  )
}
