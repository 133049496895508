import { Folder } from '@phosphor-icons/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import EmptySearchResults from '@components/Header/components/EmptySearchResults'
import LoadingSearchResults from '@components/Header/components/LoadingSearchResults'
import ShowMoreResultsButton, {
  MAXIMUM_SEARCH_RESULTS,
} from '@components/Header/components/ShowMoreResultsButton'
import { useSurveys } from '@hooks/use-survey-provider-service-api'
import type { Operator } from '@views/participants/components/ParticipantsFilterOperator'
import SurveyStatusBadge from '@views/surveys/SurveyStatusBadge'

type Props = {
  searchKeyword: string
}

export default function ProjectSearch({ searchKeyword }: Props) {
  const router = useRouter()

  const searchParams = {
    name: searchKeyword,
    folderName: searchKeyword,
    operator: 'OR' as Operator,
  }

  const { isLoadingSurveys, surveys } = useSurveys({ searchParams })

  const resultsCount = surveys?.length

  const handleShowAllSurveysClick = (e) => {
    e.preventDefault()

    const searchResultsURL = `/projects?search=${searchKeyword}&f=ALL`
    router.replace(searchResultsURL)
  }

  if (isLoadingSurveys) {
    return <LoadingSearchResults />
  }

  if (surveys?.length === 0) {
    return <EmptySearchResults searchContext='projects' />
  }

  return (
    <div>
      {surveys?.slice(0, MAXIMUM_SEARCH_RESULTS)?.map((survey) => {
        return (
          <Link
            key={survey.id}
            className='flex p-3 hover:bg-gray-100'
            href={`/survey/${survey.id}`}
          >
            <div className='flex w-full items-center justify-between'>
              <div className='flex flex-col gap-1'>
                <span className='flex items-center gap-2 text-sm text-gray-600'>
                  <Folder className='shrink-0' size={16} />
                  {survey.folderName}
                </span>
                <span>{survey.name}</span>
              </div>
              <SurveyStatusBadge surveyStatus={survey.surveyStatus} />
            </div>
          </Link>
        )
      })}

      <ShowMoreResultsButton
        handleShowAll={handleShowAllSurveysClick}
        resultsCount={resultsCount}
      />
    </div>
  )
}
