import { LogoSymbol } from '@components/Logo'

type Props = {
  message?: string | React.ReactNode
}

export function OverlayLoader({ message }: Props) {
  return (
    <div className='fixed inset-0 z-100 flex flex-col items-center justify-center bg-white pb-10'>
      <Loader message={message} />
    </div>
  )
}

export default function Loader({ message }: Props) {
  return (
    <>
      <div className='mb-6 flex flex-col items-center'>
        <span className='mb-3 animate-papperPulse align-middle text-[80px]'>
          <LogoSymbol className='mb-2' />
        </span>
        <div className='h-0.5 w-24 scale-50 animate-shadowPulse rounded-[100%] bg-[black] shadow-pulse' />
      </div>

      {message && <p className='text-center font-medium'>{message}...</p>}
    </>
  )
}
