import classnames from 'classnames'

type Props = {
  className?: string
}

export default function Logo({ className }: Props) {
  const classNames = classnames('select-none', className)

  return (
    <svg
      className={classNames}
      fill='none'
      height='30'
      viewBox='0 0 126 30'
      width='126'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48.317 23.1367H44.3949V9.30915H48.317V23.1367ZM48.6856 5.31335C48.6856 6.0319 48.4487 6.60147 47.9748 7.02208C47.5186 7.42517 46.9745 7.62671 46.3428 7.62671C45.711 7.62671 45.1582 7.42517 44.6844 7.02208C44.2281 6.60147 44 6.0319 44 5.31335C44 4.59481 44.2281 4.034 44.6844 3.63091C45.1582 3.21031 45.711 3 46.3428 3C46.9745 3 47.5186 3.21031 47.9748 3.63091C48.4487 4.034 48.6856 4.59481 48.6856 5.31335Z'
        fill='currentColor'
      />
      <path
        d='M54.2941 16.3281C54.2941 18.8342 55.2242 20.0873 57.0844 20.0873C57.488 20.0873 57.8829 20.0347 58.269 19.9295C58.6726 19.8244 58.9972 19.693 59.2429 19.5352V12.4637C59.0499 12.4287 58.8042 12.4024 58.5059 12.3849C58.2075 12.3498 57.8829 12.3323 57.5319 12.3323C56.4614 12.3323 55.6542 12.7003 55.1101 13.4364C54.5661 14.1549 54.2941 15.1188 54.2941 16.3281ZM50.2929 16.2229C50.2929 15.1539 50.4509 14.1812 50.7668 13.3049C51.1002 12.4111 51.574 11.6488 52.1882 11.0179C52.8025 10.3694 53.5571 9.86996 54.4521 9.51945C55.347 9.16894 56.3649 8.99369 57.5056 8.99369C57.9794 8.99369 58.4708 9.01998 58.9797 9.07255C59.5062 9.1076 60.0151 9.16018 60.5064 9.23028C61.0154 9.30038 61.498 9.38801 61.9542 9.49316C62.4105 9.58079 62.8141 9.67718 63.1651 9.78233V28H59.2429V22.795C58.8042 22.9877 58.3479 23.1367 57.8741 23.2418C57.4003 23.347 56.8914 23.3996 56.3473 23.3996C54.3819 23.3996 52.8814 22.7599 51.846 21.4805C50.8106 20.1837 50.2929 18.4311 50.2929 16.2229Z'
        fill='currentColor'
      />
      <path
        d='M77.6039 22.6635C76.9371 22.8563 76.0772 23.0315 75.0242 23.1893C73.9713 23.3645 72.8657 23.4522 71.7075 23.4522C70.5317 23.4522 69.549 23.2944 68.7593 22.979C67.9871 22.6635 67.3729 22.2254 66.9166 21.6646C66.4603 21.0862 66.1357 20.4027 65.9426 19.6141C65.7496 18.8254 65.6531 17.9579 65.6531 17.0116V9.30915H69.5753V16.5384C69.5753 17.8002 69.742 18.7115 70.0754 19.2723C70.4089 19.8332 71.0318 20.1136 71.9444 20.1136C72.2252 20.1136 72.5235 20.1048 72.8394 20.0873C73.1553 20.0522 73.4361 20.0172 73.6817 19.9821V9.30915H77.6039V22.6635Z'
        fill='currentColor'
      />
      <path
        d='M88.5773 12.7792C88.2263 12.6916 87.8139 12.6039 87.3401 12.5163C86.8663 12.4111 86.3574 12.3586 85.8134 12.3586C85.5677 12.3586 85.2693 12.3849 84.9184 12.4374C84.5849 12.4725 84.3305 12.5163 84.155 12.5689V23.1367H80.2328V10.0452C80.9347 9.79986 81.7595 9.57203 82.7072 9.36172C83.6724 9.13389 84.7429 9.01998 85.9186 9.01998C86.1292 9.01998 86.3837 9.0375 86.682 9.07255C86.9804 9.09008 87.2787 9.12513 87.577 9.17771C87.8754 9.21276 88.1737 9.26533 88.472 9.33544C88.7704 9.38801 89.0248 9.45811 89.2354 9.54574L88.5773 12.7792Z'
        fill='currentColor'
      />
      <path
        d='M95.319 20.4027C95.7051 20.4027 96.0736 20.394 96.4246 20.3764C96.7756 20.3589 97.0563 20.3326 97.2669 20.2976V17.327C97.109 17.292 96.8721 17.2569 96.5562 17.2219C96.2403 17.1868 95.9508 17.1693 95.6875 17.1693C95.319 17.1693 94.968 17.1956 94.6346 17.2482C94.3187 17.2832 94.0379 17.3621 93.7922 17.4848C93.5466 17.6074 93.3535 17.7739 93.2131 17.9842C93.0727 18.1945 93.0025 18.4574 93.0025 18.7729C93.0025 19.3863 93.2043 19.8156 93.608 20.061C94.0291 20.2888 94.5995 20.4027 95.319 20.4027ZM95.0031 8.94111C96.1614 8.94111 97.1265 9.07255 97.8987 9.33544C98.6708 9.59832 99.2851 9.97511 99.7413 10.4658C100.215 10.9565 100.549 11.5524 100.742 12.2534C100.935 12.9544 101.031 13.7343 101.031 14.5931V22.7424C100.47 22.8651 99.6887 23.0053 98.6884 23.163C97.6881 23.3382 96.4772 23.4259 95.0558 23.4259C94.1608 23.4259 93.3447 23.347 92.6077 23.1893C91.8882 23.0315 91.2652 22.7774 90.7387 22.4269C90.2122 22.0589 89.8086 21.5857 89.5278 21.0074C89.2471 20.429 89.1067 19.7192 89.1067 18.878C89.1067 18.0719 89.2646 17.3884 89.5805 16.8275C89.9139 16.2667 90.3526 15.8198 90.8967 15.4869C91.4407 15.1539 92.0637 14.9173 92.7656 14.7771C93.4676 14.6193 94.1959 14.5405 94.9505 14.5405C95.4594 14.5405 95.9069 14.5668 96.293 14.6193C96.6966 14.6544 97.0212 14.707 97.2669 14.7771V14.409C97.2669 13.7431 97.0651 13.2086 96.6615 12.8055C96.2579 12.4024 95.5559 12.2008 94.5556 12.2008C93.8888 12.2008 93.2307 12.2534 92.5814 12.3586C91.932 12.4462 91.3705 12.5776 90.8967 12.7529L90.3965 9.59832C90.6246 9.52821 90.9054 9.45811 91.2389 9.38801C91.5898 9.30038 91.9671 9.23028 92.3708 9.17771C92.7744 9.10761 93.1956 9.05503 93.6343 9.01998C94.0906 8.9674 94.5468 8.94111 95.0031 8.94111Z'
        fill='currentColor'
      />
      <path
        d='M103.393 5.8654L107.315 5.23449V9.30915H112.027V12.5689H107.315V17.4322C107.315 18.2559 107.456 18.9131 107.737 19.4038C108.035 19.8945 108.623 20.1399 109.5 20.1399C109.922 20.1399 110.351 20.1048 110.79 20.0347C111.246 19.9471 111.659 19.8332 112.027 19.693L112.58 22.7424C112.106 22.9352 111.58 23.1016 111.001 23.2418C110.422 23.3821 109.711 23.4522 108.869 23.4522C107.798 23.4522 106.912 23.312 106.21 23.0315C105.508 22.7336 104.946 22.3305 104.525 21.8223C104.104 21.2965 103.806 20.6656 103.63 19.9295C103.472 19.1935 103.393 18.3785 103.393 17.4848V5.8654Z'
        fill='currentColor'
      />
      <path
        d='M112.996 16.3281C112.996 15.1013 113.18 14.0322 113.549 13.1209C113.935 12.1921 114.435 11.421 115.049 10.8076C115.664 10.1942 116.366 9.72976 117.155 9.4143C117.963 9.09884 118.787 8.94111 119.63 8.94111C121.595 8.94111 123.148 9.54574 124.289 10.755C125.43 11.9467 126 13.708 126 16.0389C126 16.2667 125.991 16.5209 125.974 16.8013C125.956 17.0641 125.939 17.3007 125.921 17.511H117.024C117.111 18.3172 117.489 18.9569 118.156 19.4301C118.822 19.9033 119.717 20.1399 120.841 20.1399C121.56 20.1399 122.262 20.0785 122.946 19.9558C123.648 19.8156 124.219 19.6491 124.658 19.4564L125.184 22.6372C124.973 22.7424 124.693 22.8475 124.342 22.9527C123.991 23.0578 123.596 23.1455 123.157 23.2156C122.736 23.3032 122.28 23.3733 121.788 23.4259C121.297 23.4784 120.806 23.5047 120.314 23.5047C119.068 23.5047 117.98 23.3207 117.05 22.9527C116.137 22.5846 115.374 22.0852 114.76 21.4543C114.163 20.8058 113.716 20.0435 113.417 19.1672C113.137 18.2909 112.996 17.3445 112.996 16.3281ZM122.209 14.8297C122.192 14.4967 122.13 14.1724 122.025 13.857C121.937 13.5415 121.788 13.2611 121.578 13.0158C121.385 12.7704 121.13 12.5689 120.814 12.4111C120.516 12.2534 120.139 12.1746 119.682 12.1746C119.244 12.1746 118.866 12.2534 118.55 12.4111C118.235 12.5513 117.971 12.7441 117.761 12.9895C117.55 13.2348 117.383 13.524 117.261 13.857C117.155 14.1724 117.076 14.4967 117.024 14.8297H122.209Z'
        fill='currentColor'
      />
      <path
        d='M26.5825 15.19C28.6798 15.19 30.4267 13.464 29.9078 11.4319C29.4842 9.77331 28.7812 8.18934 27.82 6.75089C26.1509 4.25291 23.7786 2.30597 21.003 1.15628C18.2274 0.00658147 15.1732 -0.294231 12.2266 0.291878C9.28001 0.877988 6.57341 2.32469 4.44905 4.44905C2.32469 6.57341 0.877988 9.28001 0.291878 12.2266C-0.294231 15.1732 0.00658158 18.2274 1.15628 21.003C2.30597 23.7786 4.25291 26.1509 6.75089 27.82C8.18934 28.7812 9.77331 29.4842 11.4319 29.9078C13.464 30.4267 15.19 28.6798 15.19 26.5825C15.19 24.4852 13.3893 22.8607 11.5487 21.8552C11.3514 21.7474 11.1584 21.6306 10.9704 21.505C9.72146 20.6705 8.74799 19.4843 8.17314 18.0965C7.59829 16.7087 7.44789 15.1816 7.74094 13.7083C8.034 12.235 8.75735 10.8817 9.81953 9.81953C10.8817 8.75735 12.235 8.034 13.7083 7.74094C15.1816 7.44789 16.7087 7.59829 18.0965 8.17314C19.4843 8.74799 20.6705 9.72146 21.505 10.9704C21.6306 11.1584 21.7474 11.3514 21.8552 11.5487C22.8607 13.3893 24.4852 15.19 26.5825 15.19Z'
        fill='url(#paint0_linear_110_33431)'
      />
      <path
        d='M27.0382 23.2407C27.0382 25.2541 25.406 26.8863 23.3926 26.8863C21.3792 26.8863 19.747 25.2541 19.747 23.2407C19.747 21.2273 21.3792 19.5951 23.3926 19.5951C25.406 19.5951 27.0382 21.2273 27.0382 23.2407Z'
        fill='url(#paint1_linear_110_33431)'
      />
      <defs>
        <linearGradient
          gradientUnits='userSpaceOnUse'
          id='paint0_linear_110_33431'
          x1='28.9369'
          x2='11.8822'
          y1='2.81015'
          y2='28.6906'
        >
          <stop stopColor='#6366F1' />
          <stop offset='1' stopColor='#34DBFC' />
        </linearGradient>
        <linearGradient
          gradientUnits='userSpaceOnUse'
          id='paint1_linear_110_33431'
          x1='28.9369'
          x2='11.8822'
          y1='2.81015'
          y2='28.6906'
        >
          <stop stopColor='#6366F1' />
          <stop offset='1' stopColor='#34DBFC' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function LogoSymbol({ className }: Props) {
  const classNames = classnames('select-none', className)

  return (
    <svg
      className={classNames}
      fill='none'
      height='60'
      viewBox='0 0 30 30'
      width='60'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.5825 15.19C28.6798 15.19 30.4267 13.464 29.9078 11.4319C29.4842 9.77331 28.7812 8.18934 27.82 6.75089C26.1509 4.25291 23.7786 2.30597 21.003 1.15628C18.2274 0.00658147 15.1732 -0.294231 12.2266 0.291878C9.28001 0.877988 6.57341 2.32469 4.44905 4.44905C2.32469 6.57341 0.877988 9.28001 0.291878 12.2266C-0.294231 15.1732 0.00658158 18.2274 1.15628 21.003C2.30597 23.7786 4.25291 26.1509 6.75089 27.82C8.18934 28.7812 9.77331 29.4842 11.4319 29.9078C13.464 30.4267 15.19 28.6798 15.19 26.5825C15.19 24.4852 13.3893 22.8607 11.5487 21.8552C11.3514 21.7474 11.1584 21.6306 10.9704 21.505C9.72146 20.6705 8.74799 19.4843 8.17314 18.0965C7.59829 16.7087 7.44789 15.1816 7.74094 13.7083C8.034 12.235 8.75735 10.8817 9.81953 9.81953C10.8817 8.75735 12.235 8.034 13.7083 7.74094C15.1816 7.44789 16.7087 7.59829 18.0965 8.17314C19.4843 8.74799 20.6705 9.72146 21.505 10.9704C21.6306 11.1584 21.7474 11.3514 21.8552 11.5487C22.8607 13.3893 24.4852 15.19 26.5825 15.19Z'
        fill='url(#paint0_linear_110_33431)'
      />
      <path
        d='M27.0382 23.2407C27.0382 25.2541 25.406 26.8863 23.3926 26.8863C21.3792 26.8863 19.747 25.2541 19.747 23.2407C19.747 21.2273 21.3792 19.5951 23.3926 19.5951C25.406 19.5951 27.0382 21.2273 27.0382 23.2407Z'
        fill='url(#paint1_linear_110_33431)'
      />
      <defs>
        <linearGradient
          gradientUnits='userSpaceOnUse'
          id='paint0_linear_110_33431'
          x1='28.9369'
          x2='11.8822'
          y1='2.81015'
          y2='28.6906'
        >
          <stop stopColor='#6366F1' />
          <stop offset='1' stopColor='#34DBFC' />
        </linearGradient>
        <linearGradient
          gradientUnits='userSpaceOnUse'
          id='paint1_linear_110_33431'
          x1='28.9369'
          x2='11.8822'
          y1='2.81015'
          y2='28.6906'
        >
          <stop stopColor='#6366F1' />
          <stop offset='1' stopColor='#34DBFC' />
        </linearGradient>
      </defs>
    </svg>
  )
}
