import { useEffect, useState } from 'react'

import { Spinner } from '@phosphor-icons/react'
import { useDebounce } from '@uidotdev/usehooks'
import { InputText } from 'primereact/inputtext'

import SearchResults from '@components/Header/components/SearchResults'
import type { SearchContext } from '@components/Header/index'

type Props = {
  searchContext: SearchContext
}

const MIN_SEARCH_LENGTH = 3

export default function Search({ searchContext }: Props) {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedSearchKeyword = useDebounce(searchKeyword, 400)

  const shouldShowSearchResults = debouncedSearchKeyword.length >= MIN_SEARCH_LENGTH

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target

    setSearchKeyword(value)

    if (value.length >= MIN_SEARCH_LENGTH) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (shouldShowSearchResults) {
      setIsLoading(false)
    }
  }, [debouncedSearchKeyword])

  return (
    <>
      <InputText
        autoFocus
        className='border-none shadow-none focus:!shadow-none focus:!outline-none'
        placeholder={`search ${searchContext}`}
        onChange={handleSearchKeywordChange}
      />

      {isLoading && <Spinner className='absolute right-4 animate-spin' />}

      <>
        {shouldShowSearchResults && (
          <SearchResults searchContext={searchContext} searchKeyword={debouncedSearchKeyword} />
        )}
      </>
    </>
  )
}
