import { SealCheck } from '@phosphor-icons/react'
import { Tooltip } from 'primereact/tooltip'

export default function VerifiedBadge() {
  return (
    <>
      <SealCheck
        className='cursor-help text-green-700'
        data-tooltip='verifiedBadge'
        size={20}
        weight='fill'
      />
      <Tooltip
        content='Participant verified'
        position='top'
        target='[data-tooltip=verifiedBadge]'
      />
    </>
  )
}
