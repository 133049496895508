import { hslToColorString } from 'polished'

export function getColorFromDOM(colorVariableName: string): string | null {
  if (typeof document === 'undefined') {
    return null
  }

  const htmlElement = document.documentElement

  // read primary color from css variables
  const [hue, saturation, lightness] = getComputedStyle(htmlElement)
    .getPropertyValue(colorVariableName)
    .trim()
    .split(' ')
    .map((color) => parseInt(color))

  // transform rgb to hex
  return hslToColorString({ hue, lightness: lightness / 100, saturation: saturation / 100 })
}
