import { MAX_RETRY_COUNT_ON_ERROR, RETRY_TIMEOUT_SECONDS } from 'config/swr/options'

import { trackError } from '@helpers/track-error'
import { loginLink } from '@hooks/use-user'

export const onError = (error) => {
  const { status } = error

  trackError(error)

  if (status === 401) {
    location.href = loginLink

    return null
  }

  if (status === 403) {
    location.href = '/access-denied'

    return
  }
}

export const onErrorRetry = (error, key, config, revalidate, { retryCount }) => {
  const { status } = error

  if (status === 401 || status === 403) {
    return
  }

  if (retryCount >= MAX_RETRY_COUNT_ON_ERROR) return
  setTimeout(() => revalidate({ retryCount }), RETRY_TIMEOUT_SECONDS * 1000)
}
