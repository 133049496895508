import { convertHexToHsl } from '@components/Layout/SurveyFormLayout/helpers/conver-hex-to-hsl'

import type { Branding } from '@typings/Organisation'

type PrimaryVariablesArgs = {
  primaryColor?: string
  primaryColorLight?: string
  textColor?: string
}

export function getPrimaryVariables({
  primaryColor,
  primaryColorLight,
  textColor,
}: PrimaryVariablesArgs) {
  const primaryColorHsl = primaryColor ? convertHexToHsl(primaryColor) : null
  const primaryColorLightHsl = primaryColorLight ? convertHexToHsl(primaryColorLight) : null
  const textColorHsl = textColor ? convertHexToHsl(textColor) : null

  return {
    '--primary': primaryColorHsl,
    '--primary-light': primaryColorLightHsl,
    '--text-on-primary': textColorHsl,
  }
}

export function setGlobalColorCSSVariables(branding: Branding) {
  if (typeof document === 'undefined') {
    return null
  }

  const primaryCssColors = getPrimaryVariables({
    primaryColor: branding?.primaryColor,
    primaryColorLight: branding?.primaryColorLight,
    textColor: branding?.textColor,
  })

  Object.entries(primaryCssColors).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value)
  })
}
