import Link from 'next/link'
import { useRouter } from 'next/router'

import SearchButton from '@components/Header/components/SearchButton'
import CustomOrganizationLogo from '@components/Header/CustomOrganizationLogo'
import { checkIsNavigationItemActive } from '@components/Header/helpers/check-is-navigation-item-active'
import UserArea from '@components/Header/UserArea'
import { setGlobalColorCSSVariables } from '@components/Layout/SurveyFormLayout/helpers/set-global-color-css-variables'
import { OverlayLoader } from '@components/Loader'
import Logo from '@components/Logo'
import { cx } from '@helpers/cx'
import { type Actions, type Subjects, usePermissions } from '@hooks/use-permissions'
import { useBrandingColors } from '@hooks/use-primary-color'
import { useOrganisationBranding } from '@hooks/use-survey-provider-service-api'

import type { Branding } from '@typings/Organisation'

type AppNavigation = Array<{
  name: string
  permission?: [Actions, Subjects]
  href: string
}>

const appNavigation: AppNavigation = [
  {
    name: 'Projects',
    permission: ['read', 'Users'],
    href: '/',
  },
  {
    name: 'Participants',
    href: '/participants',
    permission: ['read', 'Projects'],
  },
  {
    name: 'Settings',
    href: '/organisation',
    permission: ['read', 'Organisation'],
  },
]

export type SearchContext = 'projects' | 'participants'

const getSearchContextType = (pathname: string): SearchContext => {
  if (pathname.includes('participants')) {
    return 'participants'
  }

  //Projects is the default search type
  return 'projects'
}

export default function Header() {
  const { isLoadingOrganisationBranding, organisationBranding } = useOrganisationBranding()
  const { cannot } = usePermissions()
  const router = useRouter()

  const { branding, logoUrl } = organisationBranding || {}

  useBrandingColors(organisationBranding?.branding, (branding: Branding) => {
    setGlobalColorCSSVariables(branding)
  })

  if (isLoadingOrganisationBranding) {
    return <OverlayLoader message='loading custom branding' />
  }

  return (
    <header
      className='fixed z-100 flex h-14 w-full items-center border-b bg-white'
      style={{ backgroundColor: branding?.headerBackgroundColor }}
    >
      <div
        className={cx('mx-auto flex w-full items-center justify-between px-4')}
        style={{ color: branding?.headerTextColor }}
      >
        <div className='flex flex-shrink-0 items-center gap-x-8 md:gap-x-16'>
          <Link className='relative inline-flex select-none items-center' href='/'>
            {logoUrl ? (
              <CustomOrganizationLogo logoUrl={logoUrl} />
            ) : (
              <Logo className='mb-0.5 h-7 w-auto text-gray-900 dark:text-white' />
            )}
          </Link>

          <nav className='flex gap-x-2 text-sm font-medium text-current'>
            {appNavigation.map(({ href, name, permission }) => {
              const [permissionAction, permissionSubject] = permission || []

              const shouldHideNavigationItem = permission
                ? cannot(permissionAction, permissionSubject)
                : false

              if (shouldHideNavigationItem) {
                return null
              }

              const isNavigationItemActive = checkIsNavigationItemActive(href)

              return (
                <Link
                  key={name}
                  className={cx(
                    'relative inline-flex h-14 items-center px-3 py-2 after:absolute after:bottom-px after:left-0 after:right-0 after:border-b-[2px] after:border-transparent after:opacity-90 after:transition after:content-[""] hover:after:border-current',
                    {
                      'font-bold after:border-current': isNavigationItemActive,
                    },
                  )}
                  href={href}
                >
                  {name}
                </Link>
              )
            })}
          </nav>
        </div>
        <div className='flex items-center gap-x-6'>
          <SearchButton searchContext={getSearchContextType(router.pathname)} />
          <UserArea />
        </div>
      </div>
    </header>
  )
}
