import axios from 'axios'

export const fetcher = async (url: string) => {
  try {
    const res = await axios.get(url, { withCredentials: true })

    return res.data
  } catch (err) {
    const error = new Error(`${err?.response?.data?.message || err?.response?.data}`)
    // @ts-ignore
    error.status = err?.response?.status
    throw error
  }
}
