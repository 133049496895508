import { useEffect, useState } from 'react'

import { MagnifyingGlass } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import { Button } from 'primereact/button'

import Search from '@components/Header/components/Search'
import SearchShortcut from '@components/Header/components/SearchShortcut'
import type { SearchContext } from '@components/Header/index'
import Modal from '@components/Modal'
import { useSearchKeyboardShortcut } from '@hooks/use-search-keyboard-shortcut'

type Props = {
  searchContext: SearchContext
}

export default function SearchButton({ searchContext }: Props) {
  const [isSearchVisible, setIsSearchVisible] = useState(false)

  const router = useRouter()

  useSearchKeyboardShortcut(setIsSearchVisible)

  const handleOpenSearch = () => {
    setIsSearchVisible(true)
  }

  const handleCloseSearch = () => {
    setIsSearchVisible(false)
  }

  useEffect(() => {
    const closeSearch = () => {
      setIsSearchVisible(false)
    }

    router.events.on('routeChangeStart', closeSearch)

    return () => router.events.off('routeChangeStart', closeSearch)
  }, [router])

  return (
    <>
      <Button
        className='!text-gray-400'
        icon={<MagnifyingGlass className='mr-2' size={16} />}
        outlined
        severity='secondary'
        size='small'
        onClick={handleOpenSearch}
      >
        <div className='flex items-center gap-x-2'>
          <span>Search {searchContext}...</span>
          <SearchShortcut />
        </div>
      </Button>

      {isSearchVisible && (
        <Modal
          contentClassName='!p-0 rounded-md'
          headerClassName='hidden'
          isVisible={isSearchVisible}
          maskClassName='items-start pt-[20vh]'
          size='md'
          onModalClose={handleCloseSearch}
        >
          <Search searchContext={searchContext} />
        </Modal>
      )}
    </>
  )
}
