import { PAGINATION_LIMITS } from 'config/pagination-limits'
import Link from 'next/link'
import { useRouter } from 'next/router'
import qs from 'query-string'

import EmptySearchResults from '@components/Header/components/EmptySearchResults'
import LoadingSearchResults from '@components/Header/components/LoadingSearchResults'
import ShowMoreResultsButton, {
  MAXIMUM_SEARCH_RESULTS,
} from '@components/Header/components/ShowMoreResultsButton'
import { useUsers } from '@hooks/use-response-manager-api'
import UserEmailColumn from '@views/participants/components/UserEmailColumn'

import type { UserSearchParams } from '@typings/UserDatabase'

type Props = {
  searchKeyword: string
}

export default function UserSearch({ searchKeyword }: Props) {
  const router = useRouter()

  const searchParams: Partial<UserSearchParams> = {
    email: searchKeyword,
    firstName: searchKeyword,
    lastName: searchKeyword,
    operator: 'OR',
  }

  const { isLoadingUsers, users: usersData } = useUsers({
    searchParams,
    perPage: PAGINATION_LIMITS.GLOBAL_PARTICIPANTS_SEARCH,
  })

  const { content: users, totalElements } = usersData || {}

  const resultsCount = totalElements

  const handleShowAllUsersClick = (e) => {
    e.preventDefault()

    const searchResultsURL = qs.stringifyUrl({
      url: '/participants',
      query: {
        filter: decodeURIComponent(JSON.stringify(searchParams)),
      },
    })
    router.replace(searchResultsURL)
  }

  if (isLoadingUsers) {
    return <LoadingSearchResults />
  }

  if (users?.length === 0) {
    return <EmptySearchResults searchContext='participants' />
  }

  return (
    <div>
      {users?.slice(0, MAXIMUM_SEARCH_RESULTS)?.map(({ user }) => {
        return (
          <Link
            key={user.id}
            className='flex p-3 hover:bg-gray-100'
            href={`/participants/${user.id}`}
          >
            <UserEmailColumn
              email={user.email}
              firstName={user.firstName}
              lastName={user.lastName}
            />
          </Link>
        )
      })}

      <ShowMoreResultsButton handleShowAll={handleShowAllUsersClick} resultsCount={resultsCount} />
    </div>
  )
}
