import React from 'react'

import classnames from 'classnames'

// eslint-disable-next-line no-restricted-imports
import { css } from '../Label/Label.styles'

export type Props = {
  type?: 'info' | 'warning' | 'success' | 'danger'
  className?: string
  icon?: React.ReactNode | string
  children: React.ReactNode
}

function LabelUiKit({ children, className, icon, type, ...props }: Props) {
  const classNames = classnames(
    css.default,
    {
      [css.normal]: !type,
      [css.info]: type === 'info',
      [css.warning]: type === 'warning',
      [css.success]: type === 'success',
      [css.danger]: type === 'danger',
    },
    className,
  ).replace(/\s+/g, ' ')

  const labelIcon = icon ? (
    typeof icon === 'string' ? (
      <i className={classnames('pi', `pi-${icon}`, 'mr-1.5 text-xs')} />
    ) : (
      React.cloneElement(icon as any, { className: 'mr-1', size: 16 })
    )
  ) : null

  return (
    <span className={classNames} {...props}>
      {labelIcon}
      {children}
    </span>
  )
}

export default LabelUiKit
