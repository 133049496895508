export function getMonogram(string: string) {
  try {
    // @ts-ignore it’s not recommended to use RegExp with 'u' flag
    const monogramRegExp = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
    // @ts-ignore it’s not recommended to spread IterableIterator
    const monogram = [...string?.matchAll(monogramRegExp)] || []

    if (monogram.length < 2) {
      return string.substring(0, 2).toUpperCase()
    }

    return ((monogram.shift()?.[1] || '') + (monogram.pop()?.[1] || '')).toUpperCase()
  } catch (error) {
    return string?.substring(0, 1).toUpperCase()
  }
}
