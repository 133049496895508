import { MagnifyingGlass } from '@phosphor-icons/react'

export const MAXIMUM_SEARCH_RESULTS = 4

type Props = {
  handleShowAll: (e) => void
  resultsCount: number
}

export default function ShowMoreResultsButton({ handleShowAll, resultsCount }: Props) {
  return (
    <>
      {resultsCount > MAXIMUM_SEARCH_RESULTS && (
        <div className='flex w-full cursor-pointer p-3 hover:bg-gray-100' onClick={handleShowAll}>
          <div className='flex gap-x-2 font-bold'>
            <MagnifyingGlass size={16} weight='bold' />
            <span>See all {resultsCount} results</span>
          </div>
        </div>
      )}
    </>
  )
}
