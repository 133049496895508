const projectNavigationPaths = ['/survey', '/projects', '/folder']

export const checkIsNavigationItemActive = (navigationItemPath: string) => {
  const { pathname } = location

  if (navigationItemPath === '/') {
    return pathname === '/' || projectNavigationPaths.some((path) => pathname.startsWith(path))
  }

  return pathname.startsWith(navigationItemPath)
}
