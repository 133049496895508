import { fetcher } from 'config/swr/fetcher'
import { onError, onErrorRetry } from 'config/swr/on-error-handlers'
import { DEDUPING_INTERVAL_SECONDS, FOCUS_THROTTLE_INTERVAL_SECONDS } from 'config/swr/options'

export const swrConfiguration = {
  fetcher,
  focusThrottleInterval: FOCUS_THROTTLE_INTERVAL_SECONDS * 1000,
  dedupingInterval: DEDUPING_INTERVAL_SECONDS * 1000,
  onError,
  onErrorRetry,
}
