import { hslToColorString, parseToHsl } from 'polished'

export const generateLightColor = (color: string) => {
  const lightness = 96

  const hslColor = parseToHsl(color)
  const hue = Math.round(hslColor?.hue)
  const saturation = Math.round(hslColor?.saturation * 100)

  // transform hsl to hex
  const lightColor = hslToColorString({
    hue,
    lightness: lightness / 100,
    saturation: saturation / 100,
  })

  return lightColor
}
