'use client'

import { useEffect } from 'react'

import axios from 'axios'
import toast from 'react-hot-toast'

import { trackError } from '@helpers/track-error'

function isToastExcludedUrl(url: string) {
  const excludeUrlList = [`^/api/fraud-checks/results/`, `/api/survey/(.*)/definition`]

  return excludeUrlList.some((regExp) => new RegExp(regExp, 'g').test(url))
}

axios.interceptors.response.use(null, async (error) => {
  const originalConfig = error.config

  if (error?.response?.status === 403 && !originalConfig.csrf_retry) {
    const csrfToken = await fetchAndSetCsrfToken()

    originalConfig.csrf_retry = true
    originalConfig.headers['x-csrf-token'] = csrfToken

    // HACK: to prevent race conditions
    setTimeout(() => {
      return axios.request(originalConfig)
    }, 100)
  }

  if (error?.response?.status >= 400 && error?.response?.status !== 403) {
    const errorMessage = error.response.data.backendMessage
      ? error.response.data.backendMessage
      : error.response.data.message
    if (errorMessage && typeof errorMessage === 'string') {
      const shouldShowToast = !isToastExcludedUrl(error?.config?.url)

      if (shouldShowToast) {
        toast.error(errorMessage, {
          duration: 6000,
          id: error?.config?.url,
        })
      }
    }
  }

  return Promise.reject(error)
})

export async function fetchAndSetCsrfToken(): Promise<string> {
  try {
    const { data } = await axios.get('/api/open/get-csrftoken')

    axios.defaults.headers.post = {
      'x-csrf-token': data.token,
    }

    axios.defaults.headers.delete = {
      'x-csrf-token': data.token,
    }

    return data.token
  } catch (err) {
    trackError(err)
  }
}

export function useCsrfToken() {
  useEffect(() => {
    fetchAndSetCsrfToken()
  }, [])
}
