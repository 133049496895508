import { useEffect } from 'react'

import mixpanelBrowser, { Dict, Query } from 'mixpanel-browser'

type MixpanelMethods = {
  identify: (id: string) => void
  alias: (id: string) => void
  reset: () => void
  track: (name: string, props?: Dict) => void
  track_links: (query: Query, name: string) => void
  people: {
    set: (props: Dict) => void
  }
}

const isProduction = process.env.VERCEL_ENV === 'production'

export const mixpanel: MixpanelMethods = {
  identify: (id: string) => {
    isProduction && mixpanelBrowser.identify(id)
  },
  alias: (id: string) => {
    isProduction && mixpanelBrowser.alias(id)
  },
  reset: () => {
    isProduction && mixpanelBrowser.reset()
  },
  track: (name: string, props?: Dict) => {
    isProduction && mixpanelBrowser.track(name, props)
  },
  track_links: (query: Query, name: string) => {
    isProduction &&
      mixpanelBrowser.track_links(query, name, {
        referrer: document.referrer,
      })
  },
  people: {
    set: (props: Dict) => {
      isProduction && mixpanelBrowser.people.set(props)
    },
  },
}

export function useInitMixpanel() {
  useEffect(() => {
    isProduction &&
      mixpanelBrowser.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
        track_pageview: true,
        persistence: 'localStorage',
        api_host: `${location.origin}/m`,
      })
  }, [])
}
