'use client'

import { AbilityBuilder } from '@casl/ability'
import useSWR from 'swr'

import { AbilityContext, appAbility } from '@hooks/use-permissions'
import { permissionsMapping } from 'src/client/data/permissions-mapping'

function PermissionsProvider(props: { children: React.ReactNode }) {
  const { can, cannot, rules } = new AbilityBuilder(appAbility)

  const { data } = useSWR('/api/permissions')

  data?.permissions?.forEach((rule: string) => {
    const { can: canRules, cannot: cannotRules } = permissionsMapping[rule] || {}

    canRules?.forEach(([action, subject]) => {
      can(action, subject)
    })

    cannotRules?.forEach(([action, subject]) => {
      cannot(action, subject)
    })
  })

  const ability = new appAbility(rules)
  ability.can = ability.can.bind(ability)
  ability.cannot = ability.cannot.bind(ability)

  return <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
}

export default PermissionsProvider
