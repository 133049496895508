import { useEffect } from 'react'

import { useRouter } from 'next/router'
import NProgress from 'nprogress'

import { APP_LAYOUT_TYPE } from '@constants'

import type { AppLayoutType } from '@typings/AppLayout'

export function useProgress(layoutType: AppLayoutType) {
  const router = useRouter()

  useEffect(() => {
    if (layoutType === APP_LAYOUT_TYPE.SURVEY_FORM) {
      return
    }

    const handleStart = () => {
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router, layoutType])
}
