'use client'

import { useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'

import { useUser } from '@hooks/use-user'

export default function IdentifySentryUser() {
  const { user } = useUser()

  useEffect(() => {
    if (user?.organisationId) {
      Sentry.setTag('organization', user.organisationId)
    }
  }, [user?.organisationId])

  return null
}
