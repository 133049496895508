var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a6f52e6a9cb4dede95fed421cbb893338f7f0c4f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.25,
  // integrations: [
  //   Sentry.feedbackIntegration({
  //     colorScheme: 'light',
  //     isNameRequired: true,
  //     isEmailRequired: true,
  //     showBranding: false,
  //     themeLight: {
  //       submitBackground: 'var(--primary-color)',
  //       submitBackgroundHover: 'var(--primary-color)',
  //       submitForeground: 'hsl(var(--text-on-primary))',
  //       submitForegroundHover: 'hsl(var(--text-on-primary))',
  //       submitBorder: 'var(--primary-color)',
  //       borderRadius: '0.375rem',
  //     },
  //   }),
  // ],
})
