import { useMemo, useRef } from 'react'

import { Lightbulb } from '@phosphor-icons/react'
import { OverlayPanel } from 'primereact/overlaypanel'

import { useSurveyId } from '@hooks/use-survey-id'
import { useUserRoles } from '@hooks/use-user-roles'

export default function GetReportButton() {
  const overlayPanelRef = useRef(null)
  const surveyId = useSurveyId()
  const { hasQrowdsyRole, organisationId, roles } = useUserRoles()

  const report = useMemo(() => {
    return [
      ['Organisation Id:', organisationId],
      surveyId && [`Survey Id`, surveyId],
      roles && [`Roles`, roles.join(',\n')],
    ]?.filter(Boolean)
  }, [organisationId, surveyId, roles])

  if (!hasQrowdsyRole) {
    return null
  }

  return (
    <>
      <OverlayPanel ref={overlayPanelRef} className='w-72'>
        {report?.map(([name, value], index) => (
          <div
            key={index}
            className='mb-2 border-b border-gray-100 pb-2 last:mb-0 last:border-none last:pb-0'
          >
            <div className='text-sm font-semibold'>{name}</div>
            <div className='flex flex-col whitespace-break-spaces text-xs text-gray-600'>
              {value}
            </div>
          </div>
        ))}
      </OverlayPanel>

      <a
        className='fixed bottom-4 left-4 cursor-pointer rounded-full bg-yellow-100 p-2 text-yellow-500 transition hover:bg-yellow-200 hover:text-yellow-600'
        href='#'
        onClick={(e) => {
          e.preventDefault()
          overlayPanelRef.current.toggle(e)
        }}
      >
        <Lightbulb size={20} />
      </a>
    </>
  )
}
