import type { Actions, Subjects } from '@hooks/use-permissions'

type Method = string // 'can' | 'cannot
type PermissionsMapping = Record<string, Record<Method, Array<[Actions, Subjects]>>>

export const permissionsMapping: PermissionsMapping = {
  // Analytics ========================
  'view:survey-analytics': {
    can: [['read', 'Analytics']],
  },

  // Definition =======================
  'view:survey-definition': {
    can: [['read', 'Definition']],
  },
  'edit:survey-definition': {
    can: [['update', 'Definition']],
  },

  // Organisation =====================
  'edit:organisation-metadata': {
    can: [['update', 'Organisation']],
  },
  'view:organisation-metadata': {
    can: [['read', 'Organisation']],
  },

  // Payments =========================
  'pay:response-reward': {
    can: [['manage', 'Payment']],
  },
  'edit:response-reward': {
    can: [['update', 'Payment']],
  },

  // Payment-methods =========================
  'create:payment-method': {
    can: [['create', 'PaymentMethod']],
  },
  'view:payment-method': {
    can: [['read', 'PaymentMethod']],
  },

  // Project ===========================
  'create:survey': {
    can: [['create', 'Project']],
  },
  'edit:survey': {
    can: [['update', 'Project']],
  },
  'view:survey': {
    can: [['read', 'Project']],
  },

  // ProjectStatus ====================
  'transition:survey': {
    can: [['update', 'ProjectStatus']],
  },

  // Projects =========================
  'view:surveys': {
    can: [['read', 'Projects']],
  },

  // Quota ============================
  'view:survey-quota': {
    can: [['read', 'Quota']],
  },
  'edit:survey-quota': {
    can: [['manage', 'Quota']],
  },

  // Users ============================
  // TODO: this is not good naming :(
  'notify:users': {
    can: [['update', 'Users']],
  },
  'search:users': {
    can: [['read', 'Users']],
  },

  // Email ============================
  'send:bulk-email': {
    can: [['manage', 'Emails']],
  },
  'send:email': {
    can: [['manage', 'Email']],
  },

  // SMS ============================
  'edit:sms-template': {
    can: [['manage', 'SmsTemplate']],
  },
  'view:sms-template': {
    can: [['read', 'SmsTemplate']],
  },
  'send:sms': {
    can: [['manage', 'Sms']],
  },

  // Link =============================
  'distribute:link': {
    can: [['create', 'Link']],
  },

  // Response =========================
  'view:response': {
    can: [['read', 'Response']],
  },
  'review:response': {
    can: [['update', 'Response']],
  },
  'delete:responses': {
    can: [['delete', 'Response']],
  },

  // Responses ========================
  'view:responses': {
    can: [['read', 'Responses']],
  },
  'export:csv': {
    can: [['read', 'CSV']],
  },

  // Folders ==========================
  'create:folder': {
    can: [['create', 'Folder']],
  },

  'view:folder': {
    can: [['read', 'Folder']],
  },

  'edit:folder': {
    can: [['update', 'Folder']],
  },

  // Interviews ==========================
  'create:timeslot': {
    can: [['create', 'Timeslot']],
  },

  'view:timeslot': {
    can: [['read', 'Timeslot']],
  },

  'edit:timeslot': {
    can: [['manage', 'Timeslot']],
  },

  'create:interview': {
    can: [['create', 'Interview']],
  },

  'view:interview': {
    can: [['read', 'Interview']],
  },

  'edit:interview': {
    can: [['update', 'Timeslot']],
  },

  // Calendar ============================
  'create:calendar': {
    can: [['create', 'Calendar']],
  },

  'edit:calendar': {
    can: [['update', 'Calendar']],
  },

  'view:calendar': {
    can: [['read', 'Calendar']],
  },

  // PII ==========================
  'view:pii': {
    can: [['read', 'PII']],
  },

  'edit:pii': {
    can: [['update', 'PII']],
  },

  'create:pii': {
    can: [['create', 'PII']],
  },

  // Dashboards ==========================
  'view:dashboard': {
    can: [['read', 'Dashboard']],
  },

  'edit:dashboard': {
    can: [['update', 'Dashboard']],
  },

  // Workflows ==========================
  'view:workflow': {
    can: [['read', 'Workflow']],
  },
  'edit:workflow': {
    can: [['update', 'Workflow']],
  },

  // Notifications ==========================
  'create:email-template': {
    can: [['create', 'EmailTemplate']],
  },
  'edit:email-template': {
    can: [['update', 'EmailTemplate']],
  },
  'view:email-template': {
    can: [['read', 'EmailTemplate']],
  },
}

type RoutePermissionsMapping = Record<string, [Actions, Subjects]>

export const routePermissionsMapping: RoutePermissionsMapping = {
  '/survey/[surveyId]/payments': ['manage', 'Payment'],
  '/survey/[surveyId]/settings/general': ['read', 'Definition'],
  '/survey/[surveyId]/settings/targeting': ['read', 'Definition'],
  '/survey/[surveyId]/settings/quota': ['read', 'Definition'],
  '/survey/[surveyId]/settings/workflows': ['read', 'Definition'],
  '/survey/[surveyId]/settings/survey': ['read', 'Definition'],
  '/participants': ['read', 'Users'],
}
