import { useHotjar } from '@libs/hotjar/useHotjar'
import { useInitMixpanel } from '@libs/mixpanel'
import IdentifySentryUser from '@libs/sentry/IdentifySentryUser'
import { Analytics } from '@vercel/analytics/react'
import axios from 'axios'
import { Toaster } from 'react-hot-toast'

import { APP_LAYOUT_TYPE } from '@constants'
import { useCsrfToken } from '@hooks/use-csrf-token'
import InternalAppContainer from '@views/_app/components/InternalAppContainer'
import PublicAppContainer from '@views/_app/components/PublicAppContainer'
import { useProgress } from '@views/_app/hooks/useProgress'

import type { AppLayoutType } from '@typings/AppLayout'
import type { AppProps } from 'next/app'

/* eslint-disable no-restricted-imports */
import '../styles/primereact-tailwind.css'
import '../styles/survey-js/creator.css'
import '../styles/survey-js/nouislider.css'
import '../styles/survey-js/survey-form.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/main.css'
import '../styles/sentry.css'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

axios.defaults.withCredentials = true

type ExtendedAppProps = {
  Component: AppProps['Component'] & {
    layout?: AppLayoutType
  }
} & AppProps

function App({ Component, pageProps }: ExtendedAppProps) {
  const layoutType = Component.layout

  useProgress(layoutType)
  useCsrfToken()
  useHotjar()
  useInitMixpanel()

  if (
    layoutType === APP_LAYOUT_TYPE.SURVEY_FORM ||
    layoutType === APP_LAYOUT_TYPE.INTERVIEW ||
    layoutType === APP_LAYOUT_TYPE.ACCESS_DENIED
  ) {
    return (
      <PublicAppContainer>
        <Component {...pageProps} />
        <Toaster />
      </PublicAppContainer>
    )
  }

  return (
    <InternalAppContainer>
      <IdentifySentryUser />
      <Component {...pageProps} />
      <Analytics />
      <Toaster />
    </InternalAppContainer>
  )
}

export default App
