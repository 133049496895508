export const css = {
  default: `
    inline-flex
    items-center
    justify-center
    font-semibold
    text-xs
    px-2 
    py-0.5
    rounded-2xl
    leading-0
    h-6
    whitespace-nowrap
    tracking-normal
  `,
  normal: `text-primary-600 dark:text-primary-900 bg-primary-50 dark:bg-primary-50`,
  info: `text-gray-700 bg-gray-100`,
  warning: `text-yellow-800 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-800`,
  success: `text-green-800 dark:text-green-100 bg-green-100 dark:bg-green-800`,
  danger: `text-red-800 dark:text-red-100 bg-red-100 dark:bg-red-800`,
}
