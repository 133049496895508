import { useUser } from '@hooks/use-user'

export function useUserRoles() {
  const {
    user: { organisationId, roles },
  } = useUser()

  const hasQrowdsyRole = roles.includes('qrowdsy')

  return {
    organisationId,
    roles,
    hasQrowdsyRole,
  }
}
