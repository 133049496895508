import * as Sentry from '@sentry/nextjs'

import type { Extras } from '@sentry/types'

export function trackError(exception: Error | string, extra?: Extras) {
  if (typeof exception === 'string') {
    exception = Error(exception)
  }

  const context = extra && { extra }

  Sentry.captureException(exception, context)
}
