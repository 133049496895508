import { Command } from '@phosphor-icons/react'

import { checkIsMacDevice } from '@helpers/check-is-mac-device'

export default function SearchShortcut() {
  const isMacDevice = checkIsMacDevice()

  return (
    <span className='flex items-center'>
      {isMacDevice ? (
        <>
          <Command className='ml-6' size={16} />K
        </>
      ) : (
        <>CTRL+K</>
      )}
    </span>
  )
}
