import { S_STATUS } from '@constants'
import { Label, LabelProps } from '@ui-kit/Label'

import type { SurveyItem, SurveyStatus } from '@typings/Survey'

type Props = {
  className?: string
  surveyStatus: SurveyItem['surveyStatus']
}

type StatusData = {
  [key in SurveyStatus]: {
    type: LabelProps['type']
    name: LabelProps['children']
  }
}

const statusData = {
  [S_STATUS.IN_PREPARATION]: {
    type: '',
    name: 'In Preparation',
  },
  [S_STATUS.ARCHIVED]: {
    type: 'info',
    name: 'Archived',
  },
  [S_STATUS.IN_PROGRESS]: {
    type: 'success',
    name: 'In Progress',
  },
  [S_STATUS.CLOSED]: {
    type: 'danger',
    name: 'Closed',
  },
  [S_STATUS.PAUSED]: {
    type: 'warning',
    name: 'Paused',
  },
} as StatusData

export default function SurveyStatusBadge({ className, surveyStatus }: Props) {
  const { name, type } = statusData[surveyStatus]

  return (
    <Label className={className} type={type}>
      {name}
    </Label>
  )
}
