import { UserProvider } from '@auth0/nextjs-auth0/client'
import { swrConfiguration } from 'config/swr/global-configuration'
import Head from 'next/head'
import { SWRConfig } from 'swr'

import Layout from '@components/Layout'
import PermissionsProvider from '@views/_app/contexts/PermissionsProvider'

type Props = {
  children: React.ReactNode
}

export default function InternalAppContainer({ children }: Props) {
  return (
    <SWRConfig value={swrConfiguration}>
      <Head>
        <title>iqurate app</title>
      </Head>

      <UserProvider>
        <PermissionsProvider>
          <Layout>{children}</Layout>
        </PermissionsProvider>
      </UserProvider>
    </SWRConfig>
  )
}
