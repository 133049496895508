import { useUser as useUserAuth0 } from '@auth0/nextjs-auth0/client'
import { setUser } from '@sentry/nextjs'

export type User = {
  email: string
  email_verified: boolean
  name: string
  nickname: string
  org_id: string
  picture: string
  'qrowdsy/organisationId': string
  'qrowdsy/roles': {
    roles: Array<string>
  }
  id: User['sub']
  organisationId: string
  roles: Array<string>
  sid: string
  sub: string
  updated_at: string
}

type UseUser = {
  user?: User
  isLoading: boolean
  error?: Error
  checkSession: () => Promise<void>
}

export const loginLink = '/api/auth/login'
export const logoutLink = '/api/auth/logout'

export function useUser() {
  const userData = useUserAuth0() as UseUser

  const logIn = () => {
    location.href = loginLink
  }

  const logOut = () => {
    setUser(null)
    location.href = logoutLink
  }

  return {
    ...userData,
    ...(userData.user && {
      user: {
        ...userData.user,
        organisationId: userData.user['qrowdsy/organisationId'],
        roles: userData.user['qrowdsy/roles'].roles,
        id: userData.user.sub,
      },
    }),
    logIn,
    logOut,
  }
}
