import qs from 'query-string'
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr'

import { useUser } from '@hooks/use-user'
import { useWorkflowId } from '@hooks/use-workflow-id'
import { FinalDecisionState } from '@views/payments/hooks/use-final-decision-state'

import type { ResponseLabelList } from '@typings/Labels'
import type { LabelList } from '@typings/Labels'
import type { PaymentStatusDetails, PaymentStatusType } from '@typings/Payment'
import type {
  ProjectStats,
  ResponseFlag,
  Survey,
  SurveyDecision,
  SurveyResponse,
  SurveyResponsePII,
  SurveyResponses,
  SurveyResponsesItem,
  SurveyState,
} from '@typings/Survey'
import type { PaginatedUsers, UserSearchParams } from '@typings/UserDatabase'
import type { Workflow, Workflows } from '@typings/Workflows'

type ResponseReturnType = {
  isResponseLoading: boolean
  mutateResponse: KeyedMutator<SurveyResponse>
  response: SurveyResponse
  responseError: any
}

export function useResponse(
  responseId: string | null,
  options?: SWRConfiguration,
): ResponseReturnType {
  const { data, error, isLoading, mutate } = useSWR<SurveyResponse>(
    responseId && `/api/surveyresponse/${responseId}`,
    options,
  )

  return {
    isResponseLoading: isLoading,
    response: data,
    responseError: error,
    mutateResponse: mutate,
  }
}

type ResponsesReturnType = {
  completedResponses?: SurveyResponses
  completedResponsesError?: any
  mutateCompletedResponses: KeyedMutator<SurveyResponses>
  isLoadingCompletedResponses: boolean
  isValidatingCompletedResponses: boolean
}

export function useCompletedResponses(
  surveyId: string | null,
  finalDecisionState: FinalDecisionState,
  options?: SWRConfiguration,
): ResponsesReturnType {
  const completedResponsesUrl = qs.stringifyUrl({
    url: `/api/surveyresponse/survey/${surveyId}/completed-responses`,
    query: { ...finalDecisionState },
  })

  const { data, error, isLoading, isValidating, mutate } = useSWR<SurveyResponses>(
    surveyId && completedResponsesUrl,
    options,
  )

  return {
    completedResponses: data,
    completedResponsesError: error,
    mutateCompletedResponses: mutate,
    isLoadingCompletedResponses: isLoading,
    isValidatingCompletedResponses: isValidating,
  }
}

type ResponseDecisionReturnType = {
  responseDecision: SurveyDecision
  responseDecisionError: any
  isResponseDecisionLoading: boolean
}

export function useResponseDecision(
  responseId: string | null,
  options?: SWRConfiguration,
): ResponseDecisionReturnType {
  const { data, error, isLoading } = useSWR<SurveyDecision>(
    responseId && `/api/surveyresponse/${responseId}/decision/status`,
    options,
  )

  return {
    responseDecision: data,
    responseDecisionError: error,
    isResponseDecisionLoading: isLoading,
  }
}

type SurveyReturnType = {
  isSurveyLoading: boolean
  isValidatingSurvey: boolean
  mutateSurvey?: KeyedMutator<Survey>
  survey?: Survey
  surveyError?: any
}

export function useSurvey(surveyId: string | null, options?: SWRConfiguration): SurveyReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<Survey>(
    surveyId && `/api/survey/${surveyId}/metadata`,
    options,
  )

  return {
    isSurveyLoading: isLoading,
    isValidatingSurvey: isValidating,
    mutateSurvey: mutate,
    survey: data,
    surveyError: error,
  }
}

type SurveyStateReturnType = {
  isValidatingSurveyState: boolean
  isLoadingSurveyState: boolean
  mutateSurveyState: KeyedMutator<SurveyState>
  surveyState?: SurveyState
  surveyStateError?: any
}

export function useSurveyState(
  surveyId: string | null,
  options?: SWRConfiguration,
): SurveyStateReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<SurveyState>(
    surveyId && `/api/survey/${surveyId}/status`,
    options,
  )

  return {
    surveyState: data,
    surveyStateError: error,
    mutateSurveyState: mutate,
    isValidatingSurveyState: isValidating,
    isLoadingSurveyState: isLoading,
  }
}

type ProjectStatsReturnType = {
  isValidatingProjectStats: boolean
  isLoadingProjectStats: boolean
  mutateProjectStats: KeyedMutator<ProjectStats>
  projectStats?: ProjectStats
  projectStatsError?: any
}

export function useProjectStats(
  surveyId: string | null,
  panelId?: string,
  options?: SWRConfiguration,
): ProjectStatsReturnType {
  const projectStatsUrl = qs.stringifyUrl({
    url: `/api/project/${surveyId}/decisions/stats`,
    query: panelId && { panelId },
  })

  const { data, error, isLoading, isValidating, mutate } = useSWR<ProjectStats>(
    surveyId && projectStatsUrl,
    options,
  )

  return {
    projectStats: data,
    projectStatsError: error,
    mutateProjectStats: mutate,
    isLoadingProjectStats: isLoading,
    isValidatingProjectStats: isValidating,
  }
}

type PaymentStatusReturnType = {
  isValidatingPaymentStatus: boolean
  isLoadingPaymentStatus: boolean
  mutatePaymentStatus: KeyedMutator<PaymentStatusDetails>
  paymentStatusDetail?: PaymentStatusDetails
  paymentStatusError?: any
}

type UsePaymentStatusArgs = {
  paymentType: PaymentStatusType
  responseId: string | null
  surveyId: string | null
}

export function usePaymentStatusDetail(
  { paymentType, responseId, surveyId }: UsePaymentStatusArgs,
  options?: SWRConfiguration,
): PaymentStatusReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<PaymentStatusDetails>(
    surveyId && `/api/survey/${surveyId}/response/${responseId}/payment/${paymentType}/status`,
    options,
  )

  return {
    paymentStatusDetail: data,
    paymentStatusError: error,
    mutatePaymentStatus: mutate,
    isLoadingPaymentStatus: isLoading,
    isValidatingPaymentStatus: isValidating,
  }
}

type ResponsePIIReturnType = {
  responsePII: SurveyResponsePII
  isLoadingResponsePII: boolean
  mutateResponsePII: KeyedMutator<SurveyResponsePII>
  isValidatingResponsePII?: boolean
  responsePIIError?: any
}

type UseResponsePIIArgs = {
  responseId: string | null
  surveyId: string | null
}

export function useResponsePII(
  { responseId, surveyId }: UseResponsePIIArgs,
  options?: SWRConfiguration,
): ResponsePIIReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<SurveyResponsePII>(
    surveyId && responseId && `/api/surveyresponse/survey/${surveyId}/response/${responseId}/pii`,
    options,
  )

  return {
    responsePII: data,
    responsePIIError: error,
    mutateResponsePII: mutate,
    isLoadingResponsePII: isLoading,
    isValidatingResponsePII: isValidating,
  }
}

type UseResponseFlagsArgs = {
  flags: Array<ResponseFlag>
  flagsError: any
  mutateFlags: KeyedMutator<Array<ResponseFlag>>
  isLoadingFlags: boolean
  isValidatingFlags: boolean
}

export function useResponseFlags(
  surveyId: string,
  responseId: string,
  options?: SWRConfiguration,
): UseResponseFlagsArgs {
  const { data, error, isLoading, isValidating, mutate } = useSWR<Array<ResponseFlag>>(
    surveyId && responseId && `/api/survey/${surveyId}/response/${responseId}/flag`,
    options,
  )

  return {
    flags: data,
    flagsError: error,
    mutateFlags: mutate,
    isLoadingFlags: isLoading,
    isValidatingFlags: isValidating,
  }
}

type UseWorkflows = {
  workflows: Workflows
  workflowsError: any
  mutateWorkflows: KeyedMutator<Workflows>
  isLoadingWorkflows: boolean
  isValidatingWorkflows: boolean
}

export function useWorkflows(options?: SWRConfiguration): UseWorkflows {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isLoading, isValidating, mutate } = useSWR<Workflows>(
    organisationId && `/api/organisation/${organisationId}/workflow/list`,
    options,
  )

  return {
    workflows: data,
    workflowsError: error,
    mutateWorkflows: mutate,
    isLoadingWorkflows: isLoading,
    isValidatingWorkflows: isValidating,
  }
}

type UseWorkflow = {
  workflow: Workflow
  workflowError: any
  mutateWorkflow: KeyedMutator<Workflow>
  isLoadingWorkflow: boolean
  isValidatingWorkflow: boolean
}

export function useWorkflow(surveyId: string, options?: SWRConfiguration): UseWorkflow {
  const {
    user: { organisationId },
  } = useUser()

  const workflowId = useWorkflowId(surveyId)

  const { data, error, isLoading, isValidating, mutate } = useSWR<Workflow>(
    organisationId && workflowId && `/api/organisation/${organisationId}/workflow/${workflowId}`,
    options,
  )

  return {
    workflow: data,
    workflowError: error,
    mutateWorkflow: mutate,
    isLoadingWorkflow: isLoading,
    isValidatingWorkflow: isValidating,
  }
}

type UseResponses = {
  responses: SurveyResponses
  responsesError: any
  mutateResponses: KeyedMutator<SurveyResponses>
  isLoadingResponses: boolean
  isValidatingResponses: boolean
}

export function useResponses(surveyId: string, options?: SWRConfiguration): UseResponses {
  const { data, error, isLoading, isValidating, mutate } = useSWR<SurveyResponses>(
    surveyId && `/api/surveyresponse/survey/${surveyId}/responses`,
    options,
  )

  return {
    responses: data,
    responsesError: error,
    mutateResponses: mutate,
    isLoadingResponses: isLoading,
    isValidatingResponses: isValidating,
  }
}

type UseLabels = {
  labels: LabelList
  labelsError: any
  mutateLabels: KeyedMutator<LabelList>
  isLoadingLabels: boolean
  isValidatingLabels: boolean
}

export function useLabels(options?: SWRConfiguration): UseLabels {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isLoading, isValidating, mutate } = useSWR<LabelList>(
    organisationId && `/api/organisation/${organisationId}/label/list`,
    options,
  )

  return {
    labels: data,
    labelsError: error,
    mutateLabels: mutate,
    isLoadingLabels: isLoading,
    isValidatingLabels: isValidating,
  }
}

type UseResponseLabelList = {
  responseLabelList: ResponseLabelList
  responseLabelListError: any
  mutateResponseLabelList: KeyedMutator<ResponseLabelList>
  isLoadingResponseLabelList: boolean
  isValidatingResponseLabelList: boolean
}

export function useResponseLabelList(
  responseId: string,
  options?: SWRConfiguration,
): UseResponseLabelList {
  const { data, error, isLoading, isValidating, mutate } = useSWR<ResponseLabelList>(
    responseId && `/api/surveyresponse/${responseId}/label/list`,
    options,
  )

  return {
    responseLabelList: data,
    responseLabelListError: error,
    mutateResponseLabelList: mutate,
    isLoadingResponseLabelList: isLoading,
    isValidatingResponseLabelList: isValidating,
  }
}

type ResponseItemReturnType = {
  responseItem?: SurveyResponsesItem
  responseItemError?: any
  mutateResponseItem: KeyedMutator<SurveyResponsesItem>
  isLoadingResponseItem: boolean
  isValidatingResponseItem: boolean
}

export function useResponseItem(
  {
    responseId,
    surveyId,
  }: {
    responseId: string | null
    surveyId: string | null
  },

  options?: SWRConfiguration,
): ResponseItemReturnType {
  const responseItemUrl = qs.stringifyUrl({
    url: `/api/surveyresponse/survey/${surveyId}/response-item`,
    query: { responseId },
  })

  const { data, error, isLoading, isValidating, mutate } = useSWR<SurveyResponsesItem>(
    responseId && surveyId && responseItemUrl,
    options,
  )

  return {
    responseItem: data,
    responseItemError: error,
    mutateResponseItem: mutate,
    isLoadingResponseItem: isLoading,
    isValidatingResponseItem: isValidating,
  }
}

type UsersReturnType = {
  isValidatingUsers: boolean
  isLoadingUsers: boolean
  mutateUsers: KeyedMutator<PaginatedUsers>
  users?: PaginatedUsers
  usersError?: any
}

type UseUsersArgs = {
  perPage: number
  isSearchEnabled?: boolean
  options?: SWRConfiguration
  searchParams?: Omit<UserSearchParams, 'customPII'>
} | null

export function useUsers({
  isSearchEnabled = true,
  options,
  perPage,
  searchParams,
}: UseUsersArgs): UsersReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const usersApiURL = qs.stringifyUrl({
    url: `/api/organisation/${organisationId}/users/search`,
    query: { perPage, ...searchParams },
  })

  const { data, error, isLoading, isValidating, mutate } = useSWR<PaginatedUsers>(
    isSearchEnabled && usersApiURL,
    options,
  )

  return {
    users: data,
    isLoadingUsers: isLoading,
    usersError: error,
    mutateUsers: mutate,
    isValidatingUsers: isValidating,
  }
}
