import { useState } from 'react'

import { TabMenu } from 'primereact/tabmenu'

import ProjectSearch from '@components/Header/components/ProjectSearch'
import UserSearch from '@components/Header/components/UserSearch'
import type { SearchContext } from '@components/Header/index'

type Props = {
  searchKeyword: string
  searchContext: SearchContext
}

const model = [{ label: 'Projects' }, { label: 'Participants' }]

const searchResultTabs = {
  projects: 0,
  participants: 1,
}

export default function SearchResults({ searchContext, searchKeyword }: Props) {
  const defaultActiveTab = searchResultTabs[searchContext] || 0

  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  return (
    <div className='absolute top-8 z-10 w-full rounded-md border border-gray-200 bg-white text-sm text-black'>
      <TabMenu
        activeIndex={activeTab}
        className='px-3'
        model={model}
        onTabChange={(e) => setActiveTab(e.index)}
      />

      <>
        {activeTab === 0 && <ProjectSearch searchKeyword={searchKeyword} />}
        {activeTab === 1 && <UserSearch searchKeyword={searchKeyword} />}
      </>
    </div>
  )
}
