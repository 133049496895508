export const MAX_RETRY_COUNT_ON_ERROR = 2

export const RETRY_TIMEOUT_SECONDS = 5

export const DEDUPING_INTERVAL_SECONDS = 10

export const FOCUS_THROTTLE_INTERVAL_SECONDS = 15

export const NO_REVALIDATION = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}
