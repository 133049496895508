import { parseToHsl } from 'polished'

export const convertHexToHsl = (color: string): string => {
  const hslColorObject = parseToHsl(color)

  const { hue, lightness, saturation } = hslColorObject

  const h = Math.round(hue)
  const s = Math.round(saturation * 100)
  const l = Math.round(lightness * 100)

  const hslString = `${h} ${s}% ${l}%`

  return hslString
}
