import React, { useRef } from 'react'

import { PrimeIcons } from 'primereact/api'
import { Menu, type MenuProps } from 'primereact/menu'

import { getMonogram } from '@helpers/get-monogram'
import { useUser } from '@hooks/use-user'

export default function UserArea() {
  const { isLoading, logOut, user } = useUser()

  const menuEl = useRef<Menu | null>(null)
  const menuId = 'user-area-dropdown'

  if (!user || isLoading) {
    return null
  }

  const handleButtonClick = (e) => {
    e.preventDefault()
    menuEl.current.toggle(e)
  }

  const model: MenuProps['model'] = [
    {
      template: () => {
        return (
          <div className='flex cursor-default select-none items-center justify-between px-4 py-2'>
            <span
              className='w-36 overflow-x-hidden text-ellipsis text-sm font-medium'
              title={user.name}
            >
              {user.name}
            </span>
          </div>
        )
      },
    },
    {
      separator: true,
    },
    {
      command: logOut,
      icon: PrimeIcons.SIGN_OUT,
      label: 'Sign out',
    },
  ]

  return (
    <div>
      <span
        aria-controls={menuId}
        aria-haspopup
        className='flex cursor-pointer items-center space-x-3'
        tabIndex={0}
        onClick={handleButtonClick}
      >
        <span className='inline-flex h-7 w-7 items-center justify-center overflow-hidden rounded-full bg-branding-light md:h-8 md:w-8'>
          {user?.picture ? (
            <img src={user.picture} />
          ) : (
            <span className='text-sm font-semibold text-branding'>{getMonogram(user.name)}</span>
          )}
        </span>

        <span className={`${PrimeIcons.CHEVRON_DOWN} text-sm text-current`} />
      </span>

      <Menu ref={menuEl} className='w-60' id={menuId} model={model} popup />
    </div>
  )
}
